import React from "react";
import "./Navbar.css";
const Navbar = () => {
  return (
    <div className="navbar-container">
      <div className="navbar-inner-container">
        <div className="home-logo">Pectes</div>
        <ul>
          <li className="list-item">Research</li>
          <li className="list-item">Product</li>
          <li className="list-item">Developers</li>
          <li className="list-item">Privacy</li>
          <li className="list-item">Company</li>
        </ul>
        <div className="navbar-search">Search</div>
        <div className="navbar-menu responsive">Menu</div>
      </div>
    </div>
  );
};

export default Navbar;
