import React from "react";
import "./Hero.css";
// import heroSideImage from "../../images/hero-side-image.png";
const Hero = () => {
  return (
    <div className="hero-contianer">
      <div className="hero-inner-container">
        <div className="hero-content">
          <h2 className="hero-main-header">
            The Future of Finance is <br />
            <div className="hero-coming-soon">Coming soon</div>
          </h2>
        </div>
        <div className="hero-side-image">
          {/* <img src={heroSideImage} alt="" /> */}
        </div>
      </div>
    </div>
  );
};

export default Hero;
